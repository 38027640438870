import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class OnfidoWebSdkComponent extends Component {
  @service intl
  @service router
  @service onfido

  didComplete = false

  onfidoSdkToken = this.args.onfidoForm.onfido_sdk_token
  onfidoSdkSteps = this.args.onfidoForm.onfido_sdk_steps

  async didInsert(_, [thisComponent]) {
    // FYI, this function is not a lifecycle event in Glimmer components but rather a function
    // that is called by the did-insert modifier in the hbs file, with the component context
    // passed in as `thisComponent`
    thisComponent.didComplete = false
    try {
      await thisComponent.onfido.load()
      thisComponent.onfidoSDKInit()
    } catch (error) {
      thisComponent.router.transitionTo('error').then(() => {
        throw new Error(`Onfido SDK failed to load: ${error?.srcElement?.outerHTML}`)
      })
    }
  }

  async onfidoSDKInit() {
    const sdkOptions = {
      token: this.onfidoSdkToken,
      steps: this.onfidoSdkSteps,
      onComplete: (callbackData) => {
        if (!this.didComplete) {
          this.didComplete = true
          this.args.createOnfidoCheck(callbackData)
        }
      }
    }

    this.onfidoSDK = await this.onfido.initSdk(sdkOptions, this.args.isBigBox)
  }

  willDestroy() {
    this.onfidoSDK?.tearDown()
    super.willDestroy(...arguments)
  }
}
